/* font icons */
@font-face {
    font-family: 'tui-calendar-font-icon';
    src: url('../fonts/icon.eot') format('embedded-opentype'),
         url('../fonts/icon.ttf') format('truetype'),
         url('../fonts/icon.woff') format('woff'),
         url('../fonts/icon.svg') format('svg');
}

.calendar-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

.calendar-font-icon {
  font-family: 'tui-calendar-font-icon';
  font-size: 10px;
  font-weight: normal;
}

.img-bi {
  background: url('../images/img-bi.png') no-repeat;
  width: 215px;
  height: 16px;
}

.ic_view_month {
  background: url('../images/ic-view-month.png') no-repeat;
}

.ic_view_week {
  background: url('../images/ic-view-week.png') no-repeat;
}

.ic_view_day {
  background: url('../images/ic-view-day.png') no-repeat;
}

.ic-arrow-line-left {
  background: url('../images/ic-arrow-line-left.png') no-repeat;
}

.ic-arrow-line-right {
  background: url('../images/ic-arrow-line-right.png') no-repeat;
}

.ic-travel-time {
  background: url('../images/ic-traveltime-w.png') no-repeat;
}

/* font icons */
.ic-location-b:before {
  content: '\e900';
}

.ic-lock-b:before {
  content: '\e901';
}

.ic-milestone-b:before {
  content: '\e902';
}

.ic-readonly-b:before {
  content: '\e903';
}

.ic-repeat-b:before {
  content: '\e904';
}

.ic-state-b:before {
  content: '\e905';
}

.ic-user-b:before {
  content: '\e906';
}
